import React from "react";
import "./Footer.scss";

function Footer() {
  return (
    <div className="mainFooterDiv">
      <p>Copyright Widget 4 Media (c) </p>
    </div>
  );
}

export default Footer;
