export const GET_CATEGORY_LIST_REQUEST = 'GET_CATEGORY_LIST_REQUEST';
export const GET_CATEGORY_LIST_RECEIVE = 'GET_CATEGORY_LIST_RECEIVE';
export const GET_CATEGORY_LIST_ERROR = 'GET_CATEGORY_LIST_ERROR';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_RECEIVE = 'CREATE_CATEGORY_RECEIVE';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';

export const GET_CATEGORY_DETAILS_REQUEST = 'GET_CATEGORY_DETAILS_REQUEST';
export const GET_CATEGORY_DETAILS_RECEIVE = 'GET_CATEGORY_DETAILS_RECEIVE';
export const GET_CATEGORY_DETAILS_ERROR = 'GET_CATEGORY_DETAILS_ERROR';

export const UPDATE_CATEGORY_DETAILS_REQUEST = 'UPDATE_CATEGORY_DETAILS_REQUEST';
export const UPDATE_CATEGORY_DETAILS_RECEIVE = 'UPDATE_CATEGORY_DETAILS_RECEIVE';
export const UPDATE_CATEGORY_DETAILS_ERROR = 'UPDATE_CATEGORY_DETAILS_ERROR';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_RECEIVE = 'DELETE_CATEGORY_RECEIVE';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export const BIND_CATEGORY_REQUEST = 'BIND_CATEGORY_REQUEST';
export const BIND_CATEGORY_RECEIVE = 'BIND_CATEGORY_RECEIVE';
export const BIND_CATEGORY_ERROR = 'BIND_CATEGORY_ERROR';

export const UNBIND_CATEGORY_REQUEST = 'UNBIND_CATEGORY_REQUEST';
export const UNBIND_CATEGORY_RECEIVE = 'UNBIND_CATEGORY_RECEIVE';
export const UNBIND_CATEGORY_ERROR = 'UNBIND_CATEGORY_ERROR';