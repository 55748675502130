export const GET_SELF_USER_REQUEST = 'GET_SELF_USER_REQUEST';
export const GET_SELF_USER_RECEIVE = 'GET_SELF_USER_RECEIVE';
export const GET_SELF_USER_ERROR = 'GET_SELF_USER_ERROR';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_RECEIVE = 'CREATE_USER_RECEIVE';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const GET_USERS_LIST_REQUEST = 'GET_USERS_LIST_REQUEST';
export const GET_USERS_LIST_RECEIVE = 'GET_USERS_LIST_RECEIVE';
export const GET_USERS_LIST_ERROR = 'GET_USERS_LIST_ERROR';

export const CHANGE_SELF_USER_PASS_REQUEST = 'CHANGE_SELF_USER_PASS_REQUEST';
export const CHANGE_SELF_USER_PASS_RECEIVE = 'CHANGE_SELF_USER_PASS_RECEIVE';
export const CHANGE_SELF_USER_PASS_ERROR = 'CHANGE_SELF_USER_PASS_ERROR';

export const GET_SPEC_USER_DETAILS_REQUEST = 'GET_SPEC_USER_DETAILS_REQUEST';
export const GET_SPEC_USER_DETAILS_RECEIVE = 'GET_SPEC_USER_DETAILS_RECEIVE';
export const GET_SPEC_USER_DETAILS_ERROR = 'GET_SPEC_USER_DETAILS_ERROR';

export const UPDATE_SPEC_USER_REQUEST = 'UPDATE_SPEC_USER_REQUEST';
export const UPDATE_SPEC_USER_RECEIVE = 'UPDATE_SPEC_USER_RECEIVE';
export const UPDATE_SPEC_USER_ERROR = 'UPDATE_SPEC_USER_ERROR';

export const DELETE_SPEC_USER_REQUEST = 'DELETE_SPEC_USER_REQUEST';
export const DELETE_SPEC_USER_RECEIVE = 'DELETE_SPEC_USER_RECEIVE';
export const DELETE_SPEC_USER_ERROR = 'DELETE_SPEC_USER_ERROR';
