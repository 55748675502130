export const GET_SITES_LIST_REQUEST = 'GET_SITES_LIST_REQUEST';
export const GET_SITES_LIST_RECEIVE = 'GET_SITES_LIST_RECEIVE';
export const GET_SITES_LIST_ERROR = 'GET_SITES_LIST_ERROR';

export const CREATE_SITE_REQUEST = 'CREATE_SITE_REQUEST';
export const CREATE_SITE_RECEIVE = 'CREATE_SITE_RECEIVE';
export const CREATE_SITE_ERROR = 'CREATE_SITE_ERROR';

export const GET_SITE_DETAILS_REQUEST = 'GET_SITE_DETAILS_REQUEST';
export const GET_SITE_DETAILS_RECEIVE = 'GET_SITE_DETAILS_RECEIVE';
export const GET_SITE_DETAILS_ERROR = 'GET_SITE_DETAILS_ERROR';

export const UPDATE_SITE_DETAILS_REQUEST = 'UPDATE_SITE_DETAILS_REQUEST';
export const UPDATE_SITE_DETAILS_RECEIVE = 'UPDATE_SITE_DETAILS_RECEIVE';
export const UPDATE_SITE_DETAILS_ERROR = 'UPDATE_SITE_DETAILS_ERROR';

export const DELETE_SITE_REQUEST = 'DELETE_SITE_REQUEST';
export const DELETE_SITE_RECEIVE = 'DELETE_SITE_RECEIVE';
export const DELETE_SITE_ERROR = 'DELETE_SITE_ERROR';